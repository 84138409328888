<template>
  <div class=''>
    <div>
      <v-select
      v-model="range"
      :items="ranges"
      label="Período"
      item-text="name"
      item-value="id"
      filled
      dense
      ></v-select>
    </div>
    <div><v-btn @click="getTodayCalls()">Get Calls</v-btn></div>
    <div>{{all_calls}}</div>
  </div>
</template>

<script>
import { db } from '../../fb'
import { mapGetters } from 'vuex'

export default {
  data: () => ({
    all_calls: [],
    range: 0,
    ranges: [
        {
            name: 'Hoy',
            id: 0,
        },
        {
            name: 'Ayer',
            id: 1,
        },
        {
            name: 'Ultimoa semana',
            id: 7,
        },
        {
            name: 'Ultimo mes',
            id: 30,
        },
        {
            name: 'Ultimos 3 meses',
            id: 90,
        },
        {
            name: 'Ultimos 6 meses',
            id: 180,
        },
        {
            name: 'Ultimo año',
            id: 365,
        },
        {
            name: 'Todo',
            id: null,
        },
    ],    
  }),
  computed: {
    ...mapGetters({
      user: 'user'
    })
  },

  methods: {
    async getTodayCalls () {
      try {
        var date = new Date()
        console.log(date)
        var today = new Date(date.setHours(0, 0, 0, 0))
        // var today = new Date(date.setDate(date.getDate() - 1))
        console.log(today)
        await db.collectionGroup('calls')
          .where('datetime', '>=', today)
          .get()
          .then((calls) => {
            calls.forEach((call) => {
              const data = {
                id: call.id,
                building_id: call.data().build_id,
                duration: call.data().duration,
                mode: call.data().mode,
                answered_by: call.data().user_id,
                datetime: call.data().datetime
              }
              this.all_calls.push(data)
              console.log(data)
            })
          })
      } catch (error) {
        console.log(error)
      }
    }
  }
}
</script>
